.get-friends-container {
  width: 356px;
  background: linear-gradient(145deg, $color-2 30%, #ffe1d9 100%);
  border-radius: 20px;
  padding: 20px 20px 10px;

  h4 {
    font-size: 1.4rem;
    color: $color-3;
  }
  .icon {
    margin: 16px auto;
    width: 100%;
    text-align: center;
    i {
      font-size: 1.6rem;
      color: $color-3;
    }
  }
  ul {
    width: 100%;
    padding: 14px 0 0;

    .user-hint {
      display: grid;
      grid-template-columns: 20% 50% 30%;
      align-items: center;
      margin: 8px 0;

      img {
        height: 46px;
        width: 46px;
        border-radius: 12px;
        object-fit: cover;
        box-shadow: 0 0 2px rgba(51, 51, 51, 0.377);
      }
      p {
        text-align: left;
        font-size: 0.9rem;
        font-family: $font-3;
      }
      span {
        text-align: right;

        button {
          background: $color-1;
          color: $color-4;
          padding: 10px 24px 8px;
          transition: 0.2s;
          border-radius: 20px;
          font-size: 0.9rem;

          &:hover {
            background: $color-4;
            color: $color-1;
          }
        }
      }
    }
  }
}
